import React from 'react'

import Layout from '../../components/layout'
import { PrenatalServices } from '../../components/Services/Prenatal'

const PrenatalServicesPage = () => {
  return (
    <Layout seo={{ title: 'Prenatal Care & Obstetrics' }}>
      <PrenatalServices />
    </Layout>
  )
}

export default PrenatalServicesPage

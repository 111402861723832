import React from 'react'
import { Link } from 'gatsby'
import { INTERNAL_URLS } from '../../../config/urls'
import {
  PAGE_TITLE,
  PAGE_DESCRIPTION,
  CONGRATS_SECTION_DESCRIPTIONS,
  EXPECT_SECTION_DESCRIPTION,
  RESTRICTIONS_TITLE,
  RESTRICTIONS_HAIR_NAILS_SUBTITLE,
  RESTRICTIONS_HAIR_NAILS_DESCRIPTION,
  RESTRICTIONS_DIETARY_SUBTITLE,
  RESTRICTIONS_DIETARY_DESCRITION,
  RESTRICTIONS_OTHER_SUBTITLE,
  RESTRICTIONS_OTHER_DESCRIPTIONS,
  SAFE_MEDICATIONS_LIST,
  SAFE_ANTIBIOTICS_LIST,
  PREGNANCY_STAGES_COPY,
  PRENATAL_SERVICES_TITLE,
  PRENATAL_SERVICES_LIST,
} from './copy'

import { ServicesLayout } from '../shared/ServicesLayout'
import { ServicesHeader } from '../shared/ServicesHeader'
import { ServicesSectionText } from '../shared/ServicesSectionText'
import { ServicesSectionImg } from '../shared/ServicesSectionImg'
import { ServicesChecklist } from '../shared/ServicesChecklist'
import { Card } from '../../common/Card'
import { ServicesListCard } from '../shared/ServicesListCard'
import { Stages } from '../../common/Stages'
import RoseIcon from '../../svg/RoseIcon'

import BasketImg from '../../images/basket.png'
import ScrabbleImg from '../../images/scrabble-letters.png'
import UltrasoundImg from '../../images/heartbox-ultrasound.png'

import styles from './Prenatal.module.scss'

export const PrenatalServices = () => {
  return (
    <ServicesLayout>
      <ServicesHeader
        title={PAGE_TITLE}
        description={PAGE_DESCRIPTION}
        image={
          <ServicesSectionImg
            src={BasketImg}
            alt="Basket"
            direction="right"
            customStyles={{ container: styles.headerImg }}
          />
        }
      />

      <section className={styles.section}>
        <ServicesSectionImg
          src={ScrabbleImg}
          alt="'We are having a baby' spelled out with blocks of letters"
          direction="left"
          customStyles={{ container: 'mb-12 md-pr-16 md-mb-0' }}
        />
        <ServicesSectionText title="Congratulations!" className="md-pl-12">
          {CONGRATS_SECTION_DESCRIPTIONS.map(item => (
            <p className="mb-2">{item}</p>
          ))}
        </ServicesSectionText>
      </section>

      <ServicesListCard
        className={styles.prenatalServicesListCard}
        title={PRENATAL_SERVICES_TITLE}
        items={PRENATAL_SERVICES_LIST}
        icon={<RoseIcon />}
      />

      <section className={styles.section}>
        <div className={styles.sectionColumnLeft}>
          <ServicesSectionText
            title="What should you expect?"
            className="md-w-full mb-12"
          >
            {EXPECT_SECTION_DESCRIPTION}
          </ServicesSectionText>

          <Card component="article" className="mb-16">
            <Card.Header className="text-primary-300">
              {PREGNANCY_STAGES_COPY.title}
            </Card.Header>
            <Stages>
              {PREGNANCY_STAGES_COPY.stages.map(({ title, subtitle }, idx) => {
                return (
                  <Stages.Stage
                    title={title}
                    subtitle={subtitle}
                    index={idx}
                    isLast={idx === PREGNANCY_STAGES_COPY.stages.length - 1}
                  />
                )
              })}
            </Stages>
          </Card>

          <Card component="article" className="mb-12 text-sm">
            <Card.Header className={styles.cardHeader}>
              Medications During Pregnancy
            </Card.Header>

            <div className="mb-6">
              <Card.Subheader>Safe Medications</Card.Subheader>
              <ServicesChecklist items={SAFE_MEDICATIONS_LIST} />
            </div>

            <div>
              <Card.Subheader>Safe Antibiotics</Card.Subheader>
              <ServicesChecklist items={SAFE_ANTIBIOTICS_LIST} />
            </div>

            <div className={styles.approvedMoreInfo}>
              For more details specific to your specific care, please visit your
              provider.
            </div>
          </Card>
        </div>

        <div className={styles.sectionColumnRight}>
          <ServicesSectionImg
            src={UltrasoundImg}
            alt="Box with heart on it next to ultrasound picture"
            direction="right"
            customStyles={{ container: styles.bottomImgContainer }}
            icon={<RoseIcon />}
          />

          <Card component="article">
            <Card.Header className={styles.cardHeader}>
              {RESTRICTIONS_TITLE}
            </Card.Header>

            <div className="text-sm text-primary-500">
              <div className={styles.cardText}>
                <Card.Subheader>
                  {RESTRICTIONS_HAIR_NAILS_SUBTITLE}
                </Card.Subheader>

                <p>{RESTRICTIONS_HAIR_NAILS_DESCRIPTION}</p>
              </div>

              <div className={styles.cardText}>
                <Card.Subheader>{RESTRICTIONS_DIETARY_SUBTITLE}</Card.Subheader>
                <p>{RESTRICTIONS_DIETARY_DESCRITION}</p>
              </div>

              <div className={styles.cardText}>
                <Card.Subheader>{RESTRICTIONS_OTHER_SUBTITLE}</Card.Subheader>
                {RESTRICTIONS_OTHER_DESCRIPTIONS.map(item => (
                  <p>{item}</p>
                ))}
              </div>
            </div>
          </Card>
        </div>
      </section>
    </ServicesLayout>
  )
}

import React from 'react'

import cardStyles from '../shared/ServicesListCard/ServicesListCard.module.scss'
import styles from './Prenatal.module.scss'

export const PAGE_TITLE = 'Prenatal Care & Obstetrics'
export const PAGE_DESCRIPTION =
  'Our obstetrical services include pre-pregnancy, pregnancy and post-pregnancy care plans for both normal and high-risk pregnancies.'
export const CONGRATS_SECTION_DESCRIPTIONS = [
  'Thank you for considering our practice for your obstetrical care.',
  `Dr. Don J. Nishiguchi (aka. “Dr. Nish”) and Dr. Jerry K. Izu have decades of experience helping women throughout the entire process. They will supervise and orchestrate all your care including the delivery of your baby.`,
  `We’re excited to be a supportive part of your growing family.`,
]
export const EXPECT_SECTION_DESCRIPTION = `During each trimester, information regarding your pregnancy can change as your baby continues to grow. Therefore, it’s important to be aware of the different best practices and lifestyle choices that may impact the health of your child.`

export const PRENATAL_SERVICES_TITLE = 'Our Prenatal & Childbirth Services'
export const PRENATAL_SERVICES_LIST = [
  'Safe pre-pregnancy assessment and counseling',
  <>
    Repeated early pregnancy loss{' '}
    <span className={cardStyles.subtext}>for recurrent miscarriage</span>
  </>,
  <>
    Early pregnancy genetic counseling{' '}
    <span className={cardStyles.subtext}>
      for identifying birth defects and conditions
    </span>
  </>,
  <>
    Low-risk pregnancy care with the expectation{' '}
    <span className={cardStyles.subtext}>of normal uncomplicated birth</span>
  </>,
  <>
    Pregnancy beyond 35 years of age{' '}
    <span className={cardStyles.subtext}>
      with knowledge of the potential risks
    </span>
  </>,
  <>
    High-risk pregnancy care{' '}
    <span className={cardStyles.subtext}>(for multiple pregnancies)</span>
  </>,
  <>
    Pregnancy complications from high blood pressure{' '}
    <span className={cardStyles.subtext}>(from pre-eclampsia or diabetes)</span>
  </>,
  <>
    Women with pre-existing medical problems{' '}
    <span className={cardStyles.subtext}>
      such as thyroid disease, autoimmune disorders, kidney disease or cardiac
      disease
    </span>
  </>,
  'Operative obstetrics & caesarean section',
  'Cord blood banking',
]

export const PREGNANCY_STAGES_COPY = {
  title: 'Stages of Pregnancy',
  stages: [
    {
      title: '1st Trimester',
      subtitle: '1 to 12 weeks',
    },
    {
      title: '2nd Trimester',
      subtitle: '13 to 28 weeks',
    },
    {
      title: '3rd Trimester',
      subtitle: '29 to Delivery',
    },
  ],
}

export const SAFE_MEDICATIONS_LIST = [
  <>
    Mylicon <span className={styles.approvedItemsSubtext}>for gas relief</span>
  </>,
  <>
    Colace or Metamucil{' '}
    <span className={styles.approvedItemsSubtext}>for constipation</span>
  </>,
  <>
    Monistat (3 or 7-day cream){' '}
    <span className={styles.approvedItemsSubtext}>
      for yeast infection (only apply with fingers)
    </span>
  </>,
  <>
    Robitussin (regular strength){' '}
    <span className={styles.approvedItemsSubtext}>for cough</span>
  </>,
  <>
    Claritin or Zyrtec{' '}
    <span className={styles.approvedItemsSubtext}>
      for runny nose and allergies
    </span>
  </>,
  <>
    Cepacol{' '}
    <span className={styles.approvedItemsSubtext}>
      for throat lozenges (sore throat)
    </span>
  </>,
  <>
    Kaopectate <span className={styles.approvedItemsSubtext}>for diarrhea</span>
  </>,
  'Calcium (1200mg per day)',
  <>
    Lanolin{' '}
    <span className={styles.approvedItemsSubtext}>
      for sore, cracked nipples
    </span>
  </>,
  <>
    Novacaine{' '}
    <span className={styles.approvedItemsSubtext}>
      if dental work, must be double-shielded for x-rays
    </span>
  </>,
]

export const SAFE_ANTIBIOTICS_LIST = [
  'Amoxicillan',
  'Ceftin',
  'Keflex',
  'Penicillan',
]

export const RESTRICTIONS_TITLE = 'Restrictions to Keep in Mind'

export const RESTRICTIONS_HAIR_NAILS_SUBTITLE = `Hair & Nails`
export const RESTRICTIONS_HAIR_NAILS_DESCRIPTION = `During your pregnancy, you may get your hair colored or a perm after 12 weeks and only twice during pregnancy. You can always get your nails done but choose your salon wisely. You want to use less-toxic polish, be in a clean environment (since antibiotics to cure an infection is the last thing you want) and a well-ventilated space to limit the fumes you inhale.`

export const RESTRICTIONS_DIETARY_SUBTITLE = `Dietary`
export const RESTRICTIONS_DIETARY_DESCRITION = `Only eat fish a maximum of twice per week, but no swordfish, shark, sushi or crawfish. Sorry, but coffee is not allowed, however if it is a must-have, please only consume decaf or less than 1 cup a day.`

export const RESTRICTIONS_OTHER_SUBTITLE = `Other Restrictions`
export const RESTRICTIONS_OTHER_DESCRIPTIONS = [
  `You are generally safe flying until 28 weeks into your pregnancy.`,
  `Going to the gym is safe, but no high impact excercise. Low impact activities such as yoga, walking, low weight lifting for upper body (arms) are safe. During your third trimester you should avoid foot massages as it can stimulate labor.`,
  `No alcohol, cigarettes or drugs at any time during pregnancy.`,
  `Lastly, remember to take your prenatal vitamins every day and do not use the hot tub or jacuzzi during your pregnancy.`,
]
